import React from "react"
import l from '../../lang/lang'

const Applications = () => (
	<>
		<h1 className="serif-font text-center text-white text-shadow mt-5">
			{l('KAATSU Applications')}
		</h1>
		<div className="container py-5 px-0">
			<div className="card-deck">
				<div className="card">
					<div className="card-body py-5 text-center shadow d-flex flex-column">
						<h3 className="serif-font">{l('Recovery')}</h3>
						<img src="/assets/images/recovery_icon.png" className="d-block mx-auto my-5 w-one-third" />
						<p>{l('Recover faster from travel, training, and competition.')}</p>
						{/*<a href="/?action=why_kaatsu.recovery"*/}
						{/*   className="btn btn-sm btn-info mt-auto mx-auto serif-font">LEARN MORE</a>*/}
					</div>
				</div>
				<div className="card">
					<div className="card-body py-5 text-center shadow d-flex flex-column">
						<h3 className="serif-font">{l('Rehabilitation')}</h3>
						<img src="/assets/images/rehabilitation_icon.png" className="d-block mx-auto my-5 w-one-third"/>
						<p>{l('Rehabilitate stronger from injury and surgery.')}</p>
						{/*<a href="/?action=why_kaatsu.rehabilitation"*/}
						{/*   className="btn btn-sm btn-info mt-auto mx-auto serif-font">LEARN MORE</a>*/}
					</div>
				</div>
				<div className="card">
					<div className="card-body py-5 text-center shadow d-flex flex-column">
						<h3 className="serif-font">{l('Performance')}</h3>
						<img src="/assets/images/performance_icon.png" className="d-block mx-auto my-5 w-one-third"/>
						<p>{l('Improve your strength, speed, stamina, and mobility.')}</p>
						{/*<a href="/?action=why_kaatsu.performance"*/}
						{/*   className="btn btn-sm btn-info mt-auto mx-auto serif-font">LEARN MORE</a>*/}
					</div>
				</div>
			</div>
		</div>
	</>
)

export default Applications