import React from "react"
import l from '../../lang/lang'

const HomeText = () => (
	<div className="bg-red-gradient pb-5">
		<div id="what_is_kaatsu">
			<div className="container p-md-5 text-white" style={{borderLeft: '3px solid white'}}>
				<h1 className="serif-font mb-5">{l('What Is KAATSU?')}</h1>
				<p>{l(`Invented in Japan, engineered and designed in California, KAATSU is the pioneer in the emerging
					Blood Flow Restriction (BFR) market that automatically and safely optimizes blood circulation for
					health, fitness, rehabilitation, and recovery. KAATSU is a small automated compressor and
					pneumatic, stretchable bands which are placed around your arms or legs. The bands inflate and
					deflate in a patented sequence based on algorithms that boost circulation, improve hormonal
					balance, and develop muscle tone in a time-effective manner with a minimum of effort. KAATSU
					equipment and proprietary protocols offer unparalleled performance, precision, and safety for
					users of all ages, fitness levels, and walks of life - and can be used anywhere anytime to help
					you Recover Faster, Rehab Stronger and Perform Better.`, 'fr', 'home-main-text')}</p>
			</div>
		</div>
	</div>
)

export default HomeText