export default [
	{
		name: 'À propos',
		href: '#',
		submenu: [
			{
				name: `L'histoire`,
				href: '/a-propos'
			},
		]
	},
	{
		name: 'Contact',
		href: 'https://sport-orthese.typeform.com/to/Wn9zh2',
		blank: true,
		submenu: []
	},
	{
		name: 'Shop FR',
		href: 'https://www.sport-orthese.com/386-kaatsu-musculation-par-moderation-du-flux-sanguin',
		blank: true,
		submenu: []
	},
]