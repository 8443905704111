import React from "react"
import Carousel from "../carousel"
import testimonials from '../../data/testimonials'

const Testimonials = () => (
	<Carousel id="testimonialCarousel" name="Testimonials">
		{testimonials.map((item, k) => (
			<div className={k === 0 ? "carousel-item active" : "carousel-item"} key={k}>
				<div className="container text-center mb-5">
					<div className="col-9 mx-auto">
						<div className="row mb-5">
							<div className="col-6 col-sm-5 col-md-4 col-lg-3 mx-auto">
								<img src={item.image} alt={item.image_alt || ''} className="img-fluid rounded-circle"/>
							</div>
						</div>
						<p className="lead">{item.text}</p>
						<p><em>- {item.signature}</em></p>
					</div>
				</div>
			</div>
		))}
	</Carousel>
)

export default Testimonials