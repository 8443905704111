import React from "react"
import l from '../../lang/lang'

const LearnMore = () => (
	<div id="learn_more" className="my-5">
		<div className="container mb-5">
			<div className="row mb-5">
				<div className="col-12 my-auto order-last col-md-8 order-md-first">
					<h1 className="serif-font">{l('Anyone can recover, exercise, or rehabilitate anywhere, anytime.')}</h1>
					<hr className="my-5"/>
					<h3 className="text-center">{l('Learn more about KAATSU')}</h3>
					<link href="https://cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css" />
					<div id="mc_embed_signup" className="mb-4">
						<form action="https://kaatsu-global.us17.list-manage.com/subscribe/post?u=0defe08b4619b344eec37f3ca&amp;id=0b4b6c014c" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate="">
							<div id="mc_embed_signup_scroll">
								<input type="email" defaultValue="" name="EMAIL" className="email form-control" id="mce-EMAIL" placeholder={l('email address')} required="" />
								<div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
									<input type="text" name="b_0defe08b4619b344eec37f3ca_0b4b6c014c" tabIndex="-1" defaultValue=""/>
								</div>
								<div className="clear">
									<input type="submit" value={l('Subscribe')} name="subscribe" id="mc-embedded-subscribe" className="btn btn-info serif-font"/>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div className="col-6 mx-auto order-first col-md-4 my-auto order-md-last mb-5">
					<img src="/assets/images/learn_more.jpg" className="img-fluid shadow" />
				</div>
			</div>
		</div>
	</div>
)

export default LearnMore