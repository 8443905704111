import React from "react"

const HomeImages = () => (
	<div className="row">
		<div className="col col-sm-10 col-md-9 col-lg-8 mx-auto">
			<div className="row align-items-center">
				<div className="col mb-5">
					<a href="/?action=store.details&amp;pid=3">
						<img src="/assets/images/master_2.0.png" alt="KAATSU Master 2.0" className="img-fluid"/>
					</a>
				</div>
				<div className="col mb-5">
					<a href="/?action=store.details&amp;pid=11">
						<img src="/assets/images/cycle_2.0.png" alt="KAATSU Cycle 2.0" className="img-fluid"/>
					</a>
				</div>
			</div>
		</div>
	</div>
)

export default HomeImages