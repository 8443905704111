import React from "react"
import l from '../../lang/lang'

const Supporters = () => (
	<div id="supporters" className="mb-5">
		<div className="container">
			<h1 className="serif-font text-center my-5">{l('Some of our satisfied customers')}</h1>
			<img src="/assets/images/our_supporters_1.png" className="img-fluid d-block my-5"/>
			<img src="/assets/images/our_supporters_2.png" className="img-fluid d-block my-5"/>
		</div>
	</div>
)

export default Supporters