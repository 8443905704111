import React from "react"
import l from '../../lang/lang'

const FeaturedIn = () => (
	<>
		<h1 className="text-center text-secondary serif-font mt-5">{l('Featured In')}</h1>
		<div className="row row-cols-2 row-cols-lg-3 row-cols-xl-6 align-items-center">
			<div className="col text-center">
				<a href="/?action=media.home&amp;show=news#in_the_news">
					<img src="/assets/images/featured_in/wall_street_journal.png" alt="The Wall Street Journal" className="img-fluid p-3"/>
				</a>
			</div>
			<div className="col text-center">
				<a href="/?action=media.home&amp;show=news#in_the_news">
					<img src="/assets/images/featured_in/outside.png" alt="Outside" className="img-fluid p-3"/>
				</a>
			</div>
			<div className="col text-center">
				<a href="/?action=media.home&amp;show=news#in_the_news">
					<img src="/assets/images/featured_in/military_times.png" alt="Military Times" className="img-fluid p-3"/>
				</a>
			</div>
			<div className="col text-center">
				<a href="/?action=media.home&amp;show=news#in_the_news">
					<img src="/assets/images/featured_in/orange_county_register.png" alt="The Orange County Register" className="img-fluid p-3"/>
				</a>
			</div>
			<div className="col text-center">
				<a href="/?action=media.home&amp;show=news#in_the_news">
					<img src="/assets/images/featured_in/espn.png" alt="ESPN" className="img-fluid p-3"/>
				</a>
			</div>
			<div className="col text-center">
				<a href="/?action=media.home&amp;show=news#in_the_news">
					<img src="/assets/images/featured_in/nhk_world_japan.png" alt="NHK World Japan" className="img-fluid p-3"/>
				</a>
			</div>
		</div>
	</>
)

export default FeaturedIn