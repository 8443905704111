// import { Link } from "gatsby"
import React from "react"
import menuData from '../data/menu'

const Menu = ({ home }) => (
	<>
		<a href="/" id="logo" className="navbar-brand">KAATSU<sup><small>®</small></sup></a>
		<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#primary_navigation" aria-controls="primary_navigation" aria-expanded="false" aria-label="Toggle navigation">
			<span className="navbar-toggler-icon"></span>
		</button>
		<div className="collapse navbar-collapse" id="primary_navigation">
			<ul className="navbar-nav ml-auto">
				{menuData.map((item, k) => (
					<li className="nav-item dropdown mr-xl-3" key={k}>
						<a className={`nav-link ${home ? 'text-white' : ''} font-weight-bold ${item.submenu.length ? 'dropdown-toggle' : ''}`} data-toggle={item.submenu.length ? 'dropdown' : ''} href={item.href} role="button" aria-haspopup={item.submenu.length ? 'true' : ""} aria-expanded="false" target={item.blank ? '_blank' : ''}>
							{item.name}
						</a>
						{item.submenu.length ? (
								<div className="dropdown-menu">
									{item.submenu.map((subitem, k2) => (
										<a key={'sub-' + k2} className="dropdown-item" href={subitem.href} target={subitem.blank ? '_blank' : ''}>
											{subitem.name}
										</a>
									))}
								</div>
						) : ''}
					</li>
				))}
				<li className="nav-item">
					<a href="https://www.sport-orthese.com/386-kaatsu-musculation-par-moderation-du-flux-sanguin" className={`nav-link ${home ? 'text-white' : ''} font-weight-bold`}>
						<svg className="svg-inline--fa fa-shopping-cart fa-w-18" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shopping-cart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"></path></svg>
					</a>
				</li>
			</ul>
		</div>
	</>
)

export default Menu