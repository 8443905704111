import React from "react"
import PropTypes from "prop-types"
import l from '../lang/lang'
import SEO from "./seo"

const Carousel = ({id, name, indicators, children}) => (
	<div className="container px-0 bg-dark text-light shadow">
		<div className="card rounded-0 border-0">
			<div className="card-body text-center bg-dark">
				{name ? <h1 className="serif-font mb-5 py-5">{l(name)}</h1> : ''}
				<div id={id} className="carousel slide" data-ride="carousel" data-interval="10000">
					{indicators ?
						<ol className="carousel-indicators">
							{children.map((_, key) => (
								<li data-target={`#${id}`} data-slide-to={key} key={key} className={0 === key ? 'active' : ''}></li>
							))}
						</ol>
					: ''}
					<div className="carousel-inner">
						{children}
					</div>
					<a className="carousel-control-prev" href={`#${id}`} role="button" data-slide="prev">
						<span className="carousel-control-prev-icon" aria-hidden="true"></span>
						<span className="sr-only">{l('Previous')}</span>
					</a>
					<a className="carousel-control-next" href={`#${id}`} role="button" data-slide="next">
						<span className="carousel-control-next-icon" aria-hidden="true"></span>
						<span className="sr-only">{l('Next')}</span>
					</a>
				</div>
			</div>
		</div>
	</div>
)


Carousel.defaultProps = {
	id: `carousel`,
	name: '',
	indicators: false,
	children: [],
}

Carousel.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	indicators: PropTypes.bool,
	children: PropTypes.arrayOf(PropTypes.object).isRequired,
}


export default Carousel