import l from "../lang/lang"

export default [
	{
		image: `/assets/images/testimonials/stephen_mccain.jpg`,
		alt: `Stephen McCain`,
		text: l(`"KAATSU is a game-changer for injured people...it cuts down the recovery time by half."`, 'fr', 'testimonials-text-1'),
		signature: l(`Stephen McCain, Olympic Gymnast`, 'fr', 'testimonials-signature-1')
	},
	{
		image: `/assets/images/testimonials/joseph_mercola.jpg`,
		alt: `Joseph Mercola`,
		text: l(`"One of my top tools at home right now is KAATSU
		training with bodyweight, not only because I can maintain muscle and
		get a mitochondrial and satellite cell response with a little less
		inflammation, but also because I can't go to the gym where all the
		heavyweights are, but I can simulate that with this type of
		training."`, 'fr', 'testimonials-text-2'),
		signature: l(`Joseph Mercola, Osteopathic Physician`, 'fr', 'testimonials-signature-2')
	},
	{
		image: `/assets/images/testimonials/ben_greenfield.jpg`,
		alt: `Ben Greenfield`,
		text: l(`"After careful review, it is clear to me that the
		KAATSU equipment is the best BFR equipment on the market, as it is the
		only one that allows you to automatically cycle pressure on for 30
		seconds and off for five seconds."`, 'fr', 'testimonials-text-3'),
		signature: l(`Ben Greenfield, Triathlete, Coach, Author`, 'fr', 'testimonials-signature-3')
	},
	{
		image: `/assets/images/testimonials/bode_miller.jpg`,
		alt: `Bode Miller`,
		text: l(`"I've been getting a top-to-bottom workout with just
		KAATSU alone. That's a testament to its effectiveness in terms of not
		just building size, but building functional effectiveness. I think
		it's also going to be the most widely beneficial [training method] -
		good for people from 12 years old to 80 and from elite athletes and
		law enforcement and military to stay-at-home moms and dads. There's an
		application for every human on the planet with this."`, 'fr', 'testimonials-text-4'),
		signature: l(`Bode Miller, Gold Medal Olympic Skier`, 'fr', 'testimonials-signature-4')
	},
	{
		image: `/assets/images/testimonials/mary_chavez.jpg`,
		alt: `Mary Chavez`,
		text: l(`"I was in real pain for a groin sprain. I couldn't
		lift anything or move around without pain. I was a little nervous to
		use the KAATSU bands, but afterwards, I was able to walk around
		without pain. I was surprised. I feel wonderful now."`, 'fr', 'testimonials-text-5'),
		signature: l(`80-year-old retiree Mary Chavez`, 'fr', 'testimonials-signature-5')
	},
	{
		image: `/assets/images/testimonials/christina_newman.jpg`,
		alt: `Christina Newman`,
		text: l(`"I am in shock about how my forearms and wrists feel.
		I was experiencing such pain all the time. My skin, my muscles, my
		core, my calm sense of being have all been helped by doing the KAATSU
		Cycles daily. Everything that I do with my KAATSU bands is done slowly
		especially after a long day at work."`, 'fr', 'testimonials-text-6'),
		signature: l(`56-year-old aesthetician Christina Newman`, 'fr', 'testimonials-signature-6')
	},
	{
		image: `/assets/images/testimonials/chilton_hawk.jpg`,
		alt: `Chilton Hawk`,
		text: l(`"The reason I brought the KAATSU was for neuropathy.
		My neuropathy is caused by reduced blood flow to the legs and feet
		that create mobility and balance issues. It surprised me that the
		therapy results were immediately noticeable. I felt greater mobility,
		speed, and balance, and also noticed much better sleep at night, which
		allows greater cognitive ability. An added benefit is that I am
		building muscle mass with very few exercises. At my last medical
		checkup, my Doctor said that I had a strong pulse in my ankles."`, 'fr', 'testimonials-text-7'),
		signature: l(`Chilton Hawk, Assistant Laboratory Animal Technician`, 'fr', 'testimonials-signature-7')
	},
	{
		image: `/assets/images/testimonials/joe_lowrey.jpg`,
		alt: `Joe Lowrey`,
		text: l(`KAATSU is a remarkable therapy that I was introduced
		to while recovering from my injury suffered in Afghanistan. My
		injuries are quite profound: I am hemiplegic on the left side of my
		body. With KAATSU, I made strides to where I am able to ambulate with
		a quad cane. I have also noticed decreases in spasticity and lower
		extremity tone with the help of my KAATSU leg bands. I am indebted for
		this huge blessing that has helped me regain some independence back
		into my highly affected life due to my combat injury."`, 'fr', 'testimonials-text-8'),
		signature: l(`34-year-old Green Beret and Purple Heart recipient Joe Lowrey`, 'fr', 'testimonials-signature-8')
	},
	{
		image: `/assets/images/testimonials/michael_andrew.jpg`,
		alt: `Michael Andrew`,
		text: l(`"Leading up to swim meets, I trained with KAATSU Aqua
		Bands daily and recovered with KAATSU after each training
		session.
		During the meets, I use KAATSU after each session to
		flush out and recover especially since we did not have access to
		massage therapist. With my event schedule and length of meet, I would
		not have been able to perform my best without KAATSU to help me
		recover quicker. Also, looking back, I feel that the race pain I
		experienced when training with KAATSU Aqua bands equipped me to better
		handle consecutive no-breath 50 sprints and the rigor of an insane
		schedule the night I set three junior world records."`, 'fr', 'testimonials-text-9'),
		signature: l(`Michael Andrew, USA Swimming national team member`, 'fr', 'testimonials-signature-9')
	},
	{
		image: `/assets/images/testimonials/gamal_awad.jpg`,
		alt: `Gamal Awad`,
		text: l(`"KAATSU is an intense workout. I didn't know that I
		could feel that way so soon. I was quite surprised. I started with
		hand clenches, curls with 5 lb weight. I had more mobility. The pain
		levels in my back from 7-8 to 2-3. That enabled me to get past issues
		and do other exercises. Now I can keep going. Within a week of my
		accident, I healed so quickly. The healing process is nuts. It was a
		huge game-changer. It is life changing."`, 'fr', 'testimonials-text-10'),
		signature: l(`U.S. Marines Major Gamal Awad`, 'fr', 'testimonials-signature-10')
	},
	{
		image: `/assets/images/testimonials/hawley_bennett.jpg`,
		alt: `Hawley Bennett`,
		text: l(`"KAATSU has really helped me recover from some serious
		injuries. My husband and I got hooked on KAATSU. I don't like using
		heavy weights. I am strong, but lean, and like variety in my training.
		It is easy, to do light and efficient when I am traveling. I only
		promote stuff that works for me. The results speak for themselves; it
		works for me. I am a big believer in KAATSU."`, 'fr', 'testimonials-text-11'),
		signature: l(`3-time Olympian Hawley Bennett`, 'fr', 'testimonials-signature-11'),
	},
	{
		image: `/assets/images/testimonials/susan_friedman.jpg`,
		alt: `Susan Friedman`,
		text: l(`"After my first intensive session of KAATSU, I was
		ecstatic that my right hand that had been plaguing me with intense
		nerve pain. It was almost all gone. I couldn't sleep. I called all my
		friends at 2 in the morning telling them about this fabulous thing
		called KAATSU, this amazing extraordinary modality. I recommend it to
		anyone who is physical issues."`, 'fr', 'testimonials-text-12'),
		signature: l(`Susan Friedman, retired teacher`, 'fr', 'testimonials-signature-12'),
	},
]